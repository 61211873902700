/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Checkbox,
    Numeric,
    useFormState,
    useFormActions,
    OnlinePatientManagementContext
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles } from '@material-ui/core';

import { NumberFormatValues } from 'react-number-format';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { THREE_DIGIT_POSITIVE_INTEGER } from '../../utils/numberFormat';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IGeneralProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
const servicesAustraliaConsentYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => formState?.values.servicesAustraliaConsent === Dtos.LookupYesNoType.Yes;
const notProtocolVersion1 = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => formState?.values.protocolVersion !== (window as any).PROTOCOL_VERSION_1_ID;
const optedOutOfIndefiniteDataStorageYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => formState?.values.optedOutOfIndefiniteDataStorage === Dtos.LookupYesNoType.Yes;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const ProtocolVersionField: React.FunctionComponent = () => {
    const { values: formValues } = useFormState<Dtos.General, any>({ values: true });

    const { setFieldValue } = useFormActions();

    const { serviceStackClient: client } = React.useContext(OnlinePatientManagementContext);

    const { institution } = React.useContext(InstitutionContext);

    // set default value to latest site approved version
    //React.useEffect(() => {
    //    if (formValues?.id == null) {

    //        client
    //            .get(new Dtos.GetSiteApprovedProtocolVersion({
    //                instId: institution?.id
    //            }))
    //            .then(response => {
    //                setFieldValue('protocolVersion', response.versionId);
    //            })
    //            .catch((e) => {

    //            })
    //    }
    //}, [formValues?.id, setFieldValue, client, institution]);

    return (
        <>
            <Field
                name="protocolVersion"
                component={Select}
            />
        </>
    );
}

const General: React.FunctionComponent<IGeneralProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const { form } = React.useContext(FormContext);

    //const [loading, setLoading] = React.useState(false);

    //const setFormLoading = React.useCallback((loading: boolean) => {
    //    setLoading(loading);
    //}, [setLoading]);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.General}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="siteName"
                    component={Text}
                    label="Site name"
                    inputProps={{
                        value: institution?.name
                    }}
                    disabled
                />
                <ProtocolVersionField />
                <Field
                    name="ageAtConsent"
                    component={Numeric}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                />
                <Field
                    name="gender"
                    component={Select}
                />
                <Field
                    name="hepatologistName"
                    component={Text}
                />
                <Field
                    name="radiationOncologistName"
                    component={Text}
                />
                <Field
                    name="interventionalRadiologistName"
                    component={Text}
                />
                <Field
                    name="dateOfConsent"
                    component={KeyboardDatePicker}
                />
                <CrfCondition
                    type={Dtos.General}
                    mode="Show"
                    condition={notProtocolVersion1}
                    subscription={{ values: true }}
                >
                    <FieldGroup>
                        <Field
                            name="qualitativeSubstudyConsent"
                            component={ToggleButtonGroup}
                        />
                        <Field
                            name="optedOutOfIndefiniteDataStorage"
                            component={ToggleButtonGroup}
                        />
                        <CrfCondition
                            type={Dtos.General}
                            mode="Show"
                            condition={optedOutOfIndefiniteDataStorageYes}
                            subscription={{ values: true }}
                        >
                            <Field
                                name="dateOptedOutOfIndefiniteDataStorage"
                                component={KeyboardDatePicker}
                            />
                        </CrfCondition>
                    </FieldGroup>
                </CrfCondition>
                <FieldGroup>
                    <Field
                        name="servicesAustraliaConsent" 
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.General}
                        mode="Enable"
                        condition={servicesAustraliaConsentYes}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="servicesAustraliaDateOfConsent"
                            component={KeyboardDatePicker}
                        />
                        <Field
                            name="consentDetails"
                            component={Select}
                        />
                        <Field
                            name="dataCollectionStart"
                            component={KeyboardDatePicker}
                        />
                        <Field
                            name="dataCollectionEnd"
                            component={KeyboardDatePicker}
                            label={<><Typography>Stop date of data collection</Typography><i>(2.5 years from consent)</i></>}
                        />
                    </CrfCondition>
                </FieldGroup>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default General;
